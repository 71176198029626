var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex min-h-screen"},[_c('blogs-nav-side-bar'),_c('div',{staticClass:"flex-1 min-w-0 px-5 py-3 bg-fixed bg-blue-50 md:px-10 md:py-8"},[_c('h1',{staticClass:"mb-8 text-3xl text-center"},[_vm._v(" "+_vm._s(_vm.heading)+" ")]),(_vm.posts.length > 0 && !_vm.loading)?_c('ul',_vm._l((_vm.posts),function(post){return _c('li',{key:post.id},[_c('blog-card',{attrs:{"id":post.id,"status":post.status,"title":post.title.rendered,"excerpt":post.excerpt.rendered,"categories":post.categories.length > 0
              ? _vm.categoryListFromTerms(
                  post.categories,
                  post._embedded['wp:term']
                )
              : [],"author":post._embedded.author[0].name,"published":_vm._f("formatRestDate")(post.date),"link":_vm.fullLink(post.link),"featuredImage":post._embedded['wp:featuredmedia'] &&
            post._embedded['wp:featuredmedia'][0].code !==
              'rest_post_invalid_id'
              ? _vm.fullLink(
                  post._embedded['wp:featuredmedia'][0].media_details.sizes
                    .thumbnail.source_url
                )
              : ''},on:{"delete-post":_vm.deletePost}})],1)}),0):_vm._e(),(_vm.posts.length === 0 && !_vm.loading)?_c('p',{staticClass:"text-xl text-center text-gray-500 mt-36"},[_vm._v(" Nothing to see here... ")]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"w-24 h-24 mx-auto mt-24 border-4 border-gray-300 rounded-full animate-spin",staticStyle:{"border-top-color":"#3b82f6"}}):_vm._e(),(_vm.error)?_c('message-bar',{attrs:{"color":"error","message":_vm.error}}):_vm._e(),(_vm.totalPages > 1 && !_vm.loading)?_c('div',{staticClass:"flex justify-between max-w-screen-md m-auto mt-8"},[(_vm.currentPage > 1)?_c('a',{staticClass:"px-6 py-3 mr-2 font-semibold text-blue-500 rounded-md",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.getPosts(_vm.currentPage - 1)}}},[_vm._v("← Previous Page")]):_vm._e(),(_vm.currentPage < _vm.totalPages)?_c('a',{staticClass:"px-6 py-3 ml-auto font-semibold text-blue-500 rounded-md",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.getPosts(_vm.currentPage + 1)}}},[_vm._v("Next Page →")]):_vm._e()]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }