<template>
  <div class="flex min-h-screen">
    <blogs-nav-side-bar />
    <div class="flex-1 min-w-0 px-5 py-3 bg-fixed bg-blue-50 md:px-10 md:py-8">
      <h1 class="mb-8 text-3xl text-center">
        {{ heading }}
      </h1>
      <ul v-if="posts.length > 0 && !loading">
        <li v-for="post in posts" :key="post.id">
          <blog-card
            :id="post.id"
            :status="post.status"
            :title="post.title.rendered"
            :excerpt="post.excerpt.rendered"
            :categories="
              post.categories.length > 0
                ? categoryListFromTerms(
                    post.categories,
                    post._embedded['wp:term']
                  )
                : []
            "
            :author="post._embedded.author[0].name"
            :published="post.date | formatRestDate"
            :link="fullLink(post.link)"
            :featuredImage="
              post._embedded['wp:featuredmedia'] &&
              post._embedded['wp:featuredmedia'][0].code !==
                'rest_post_invalid_id'
                ? fullLink(
                    post._embedded['wp:featuredmedia'][0].media_details.sizes
                      .thumbnail.source_url
                  )
                : ''
            "
            @delete-post="deletePost"
          ></blog-card>
        </li>
      </ul>
      <p
        v-if="posts.length === 0 && !loading"
        class="text-xl text-center text-gray-500 mt-36"
      >
        Nothing to see here...
      </p>
      <div
        v-if="loading"
        class="w-24 h-24 mx-auto mt-24 border-4 border-gray-300 rounded-full animate-spin"
        style="border-top-color: #3b82f6"
      ></div>
      <message-bar v-if="error" color="error" :message="error" />
      <div
        v-if="totalPages > 1 && !loading"
        class="flex justify-between max-w-screen-md m-auto mt-8"
      >
        <a
          v-if="currentPage > 1"
          @click.prevent="getPosts(currentPage - 1)"
          href="#"
          class="px-6 py-3 mr-2 font-semibold text-blue-500 rounded-md"
          >← Previous Page</a
        >
        <a
          v-if="currentPage < totalPages"
          @click.prevent="getPosts(currentPage + 1)"
          href="#"
          class="px-6 py-3 ml-auto font-semibold text-blue-500 rounded-md"
          >Next Page →</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import http from "../util/http";
import cookies from "../util/cookies";
import moment from "moment";

import BlogsNavSideBar from "../components/BlogsNavSideBar";
import BlogCard from "../components/BlogCard.vue";
import MessageBar from "../components/MessageBar";

export default {
  components: {
    BlogCard,
    BlogsNavSideBar,
    MessageBar,
  },
  data() {
    return {
      siteUrl: cookies.getCookie("blog_url"),
      loading: true,
      error: "",
      posts: [],
      currentPage: 1,
      totalPages: 1,
      heading: "",
    };
  },
  watch: {
    $route(to) {
      if (to.fullPath.startsWith("/blogs")) {
        this.heading = this.pageHeadingFromRoute(to);
        this.currentPage = 1;
        this.getPosts(this.currentPage);
      }
    },
  },
  mounted() {
    this.heading = this.pageHeadingFromRoute(this.$route);
    http.router = this.$router;
    this.getPosts(this.currentPage);
  },
  methods: {
    getPosts(page) {
      const statusFilter = this.statusFilterFromRoute(this.$route);
      this.loading = true;

      http.get(
        `/wp-json/wp/v2/posts?status=${statusFilter}&_embed&page=${page}`,
        {
          onSuccess: (response) => {
            this.posts = response.data;
            this.loading = false;
            this.currentPage = page;
            this.totalPages = response.headers["x-wp-totalpages"];
          },
          onError: (error) => {
            console.log(error);
          },
        }
      );
    },
    deletePost(id) {
      if (confirm("Are you sure you want to delete this post?")) {
        http.delete(`/wp-json/wp/v2/posts/${id}`, {
          onSuccess: () => {
            this.posts = this.posts.filter((post) => post.id !== id);
          },
        });
      }
    },
    fullLink(link) {
      // Test for relative URL
      if (link.startsWith("http")) {
        return link;
      } else if (link.startsWith("/")) {
        return this.siteUrl + link;
      }
    },
    statusFilterFromRoute(routeObject) {
      const pathsToStatus = {
        "/blogs/": "publish",
        "/blogs/drafts/": "draft",
        "/blogs/pending/": "pending",
        "/blogs/scheduled/": "future",
        "/blogs/trash/": "trash",
      };

      if (routeObject.fullPath in pathsToStatus) {
        return pathsToStatus[routeObject.fullPath];
      } else if (routeObject.fullPath + "/" in pathsToStatus) {
        return pathsToStatus[routeObject.fullPath + "/"];
      } else {
        return pathsToStatus["/blogs/"];
      }
    },
    pageHeadingFromRoute(routeObject) {
      const statusesToHeading = {
        "/blogs/": "Published Blogs",
        "/blogs/drafts/": "Blog Drafts",
        "/blogs/pending/": "Pending Blogs",
        "/blogs/scheduled/": "Scheduled Blogs",
        "/blogs/trash/": "Trash",
      };

      if (routeObject.fullPath in statusesToHeading) {
        return statusesToHeading[routeObject.fullPath];
      } else if (routeObject.fullPath + "/" in statusesToHeading) {
        return statusesToHeading[routeObject.fullPath + "/"];
      } else {
        return statusesToHeading["/blogs/"];
      }
    },
    categoryListFromTerms(postCategoryIds, termsObj) {
      let results = [];

      for (const termList of termsObj) {
        for (const termItem of termList) {
          if (termItem.taxonomy === "category") {
            results.push(termItem);
          }
        }
      }

      return results;
    },
  },
  filters: {
    formatRestDate(date) {
      return moment(date).format("MMM D, YYYY");
    },
  },
};
</script>