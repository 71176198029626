import Vue from 'vue';
import VueRouter from 'vue-router';
import LogIn from '../views/LogIn.vue';
import Blogs from '../views/Blogs.vue';
import CreateBlog from '../views/CreateBlog.vue';
import EditBlog from '../views/EditBlog.vue';
import NotFound from '../views/NotFound.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'LogIn',
    component: LogIn,
    meta: {
      title: "Log In | Blog Manager"
    },
  },
  {
    path: '/blogs',
    redirect: '/blogs/published',
  },
  {
    path: '/blogs/future',
    redirect: '/blogs/scheduled',
  },
  {
    path: '/blogs/draft',
    redirect: '/blogs/drafts',
  },
  {
    path: '/blogs/:status',
    name: 'Blogs',
    component: Blogs,
    meta: {
      title: "Blogs | Blog Manager"
    },
  },
  {
    path: '/create',
    name: 'New Blog',
    component: CreateBlog,
    meta: {
      title: "New Blog | Blog Manager"
    },
  },
  {
    path: '/edit/:id',
    name: 'Edit Blog',
    component: EditBlog,
    meta: {
      title: "Edit Blog | Blog Manager"
    },
  },
  {
    path: '*',
    name: '404',
    component: NotFound,
    meta: {
      title: "404 | Blog Manager"
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || 'Blog Manager';
  });
});

export default router
