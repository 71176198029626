<template>
  <div class="flex min-h-screen">
    <blogs-nav-side-bar />
    <blog-form @postSubmitted="onSubmit" formHeading="New Blog"></blog-form>
  </div>
</template>

<script>
import http from "../util/http"

import BlogsNavSideBar from "../components/BlogsNavSideBar.vue"
import BlogForm from "../components/BlogForm.vue"

export default {
  methods: {
    onSubmit(data) {
      // console.log(data);

      http.post("/wp-json/wp/v2/posts/", {
        data: data,
        onSuccess: (response) => {
          this.$router.push(`/blogs/${response.data.status}`);
        },
      });
    },
  },
  components: {
    BlogsNavSideBar,
    BlogForm,
  },
};
</script>