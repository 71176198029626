<template>
  <input :value="value" ref="datepick" :placeholder="format" />
</template>

<script>
import Pikaday from "pikaday";
import "pikaday/css/pikaday.css";

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    format: {
      type: String,
      default: "YYYY-MM-DD",
    },
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  mounted() {
    const datePicker = new Pikaday({
      field: this.$refs.datepick,
      format: this.format,
      onSelect: () => {
        this.$emit("input", datePicker.toString());
      },
      ...this.options,
    });
  },
};
</script>