/*
 * Implements a convenient wrapper for standard axios connection behavior.
 * Makes sure correct authentication data exists and handles things when
 * it's not.
 *
 * http.get(`posts?status=publish&page=1&_embed`)
 *   .then(response => {
 *      console.log(response.data)
 *   })
 * 
 * - Check if auth cookie and site URL exist.
 * - If both exist:
 *   - Connect.
 *   - If invalid token error:
 *     - Invalidate all cookies and redirect to login.
 *   - Else:
 *     - Pass regular response data for function call to handle.
 * - If one or more don't exist:
 *   - Invalidate all cookies and redirect to login.
 */

import cookies from './cookies';
import axios from 'axios';

axios.interceptors.request.use((request) => {
    // console.log('Starting Request', JSON.stringify(request, null, 2));
    return request
});

const http = {
    router: null,

    _redirectToLogin() {
        if (!this.router) {
            throw new Error('No router set, so can\'t redirect!');
        }

        this.router.push({ name: 'LogIn' });
    },

    _checkCookies() {
        if (!cookies.getCookie('login_token') || !cookies.getCookie('blog_url'))
            this._redirectToLogin();
    },

    get(path, { onSuccess = () => { }, onError = () => { } } = {}) {
        let siteUrl = cookies.getCookie('blog_url');

        this._checkCookies();

        axios.get(siteUrl + path, {
            headers: {
                Authorization: `Bearer ${cookies.getCookie('login_token')}`,
            }
        })
            .then(response => {
                // console.log(response);

                if (!response.data.sucess && response.data.code === 'jwt_auth_invalid_token') {
                    cookies.removeCookie('login_token');
                    this._redirectToLogin();
                }

                onSuccess(response);
            }).catch((error) => {
                onError(error);
            });
    },

    post(path, { headers = {}, data = {}, onSuccess = () => { }, onError = () => { } } = {}) {
        let siteUrl = cookies.getCookie('blog_url');

        this._checkCookies();

        axios.post(siteUrl + path, data, {
            headers: {
                Authorization: `Bearer ${cookies.getCookie('login_token')}`,
                ...headers,
            }
        })
            .then(response => {
                // console.log(response);

                if (!response.data.sucess && response.data.code === 'jwt_auth_invalid_token') {
                    cookies.removeCookie('login_token');
                    this._redirectToLogin();
                }

                onSuccess(response);
            }).catch((error) => {
                onError(error);
            });
    },

    delete(path, { onSuccess = () => { }, onError = () => { } } = {}) {
        let siteUrl = cookies.getCookie('blog_url');

        this._checkCookies();

        axios.delete(siteUrl + path, {
            headers: {
                Authorization: `Bearer ${cookies.getCookie('login_token')}`,
            }
        })
            .then(response => {
                // console.log(response);

                if (!response.data.sucess && response.data.code === 'jwt_auth_invalid_token') {
                    cookies.removeCookie('login_token');
                    this._redirectToLogin();
                }

                onSuccess(response);
            }).catch((error) => {
                onError(error);
            });
    },
};

export default http;