/*
 * Utility functions for handling browser cookies.
 */

const cookies = {
    setCookie(name, value, hoursValidFor) {
        let expires = '';
    
        if (hoursValidFor) {
            let expiresDate = new Date();
            expiresDate.setTime(expiresDate.getTime() + (hoursValidFor * 60 * 60 * 1000)); // 1 hour > 60 minutes > 60 seconds > 1000 ms
            expires = `; expires=${expiresDate.toUTCString()}`;
        }
    
        value = value || '';
        document.cookie = `${name}=${value}${expires}; path=/; SameSite=Strict`;
    },

    getCookie(name) {
        const value = document.cookie
            .split('; ')
            .find(row => row.startsWith(name));
        
        return value !== undefined ? value.split('=')[1] : undefined;
    },

    removeCookie(name) {
        document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    },
};

export default cookies;