<template>
  <div class="flex min-h-screen bg-blue-50">
    <blogs-nav-side-bar />
    <blog-form
      v-if="!loading"
      @postSubmitted="onSubmit"
      formHeading="Edit Blog"
      :title="postData.title ? postData.title.raw : ''"
      :content="postData.content ? postData.content.raw : ''"
      :slug="postData.slug || ''"
      :categories="postData.categories || []"
      :excerpt="postData.excerpt ? postData.excerpt.raw : ''"
      :featuredImageId="postData.featured_media || null"
      :featuredImageAlt="
        postData._embedded
          ? postData._embedded['wp:featuredmedia'][0].alt_text
          : ''
      "
      :publishDate="postData.date || ''"
      :status="postData.status || ''"
      :keywordPhrase="postData.meta ? postData.meta._yoast_wpseo_focuskw : ''"
      :metaDescription="
        postData.meta ? postData.meta._yoast_wpseo_metadesc : ''
      "
      :seoTitle="postData.meta ? postData.meta._yoast_wpseo_title : ''"
    ></blog-form>
    <div
      v-if="loading"
      class="w-24 h-24 mx-auto mt-24 border-4 border-gray-300 rounded-full animate-spin"
      style="border-top-color: #3b82f6"
    ></div>
  </div>
</template>

<script>
import http from "../util/http";

import BlogsNavSideBar from "../components/BlogsNavSideBar";
import BlogForm from "../components/BlogForm.vue";

export default {
  data() {
    return {
      loading: true,
      postData: {},
    };
  },
  mounted() {
    http.router = this.$router;
    this.fetchPostData(this.$route.params.id);
  },
  watch: {
    $route(to) {
      if (to.fullPath.startsWith("/edit/")) {
        this.fetchPostData(this.$route.params.id);
      }
    },
  },
  methods: {
    fetchPostData(id) {
      http.get(`/wp-json/wp/v2/posts/${id}?context=edit`, {
        onSuccess: (response) => {
          this.postData = response.data;
          this.loading = false;
        },
      });
    },
    onSubmit(data) {
      // console.log(data);

      http.post(`/wp-json/wp/v2/posts/${this.$route.params.id}`, {
        data: data,
        onSuccess: (response) => {
          this.$router.push(`/blogs/${response.data.status}`);
        },
      });
    },
  },
  components: {
    BlogsNavSideBar,
    BlogForm,
  },
};
</script>