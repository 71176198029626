<template>
  <form
    tabindex="0"
    @keydown.ctrl.83.prevent.stop="submitted"
    @submit.prevent
    class="flex flex-row flex-1 max-w-full max-h-screen min-w-0 pb-4 outline-none bg-blue-50 md:pb-0"
  >
    <!-- Title & Editor -->
    <div
      class="relative flex flex-col w-full h-full max-w-screen-lg max-h-screen p-4 pb-16 mx-auto"
    >
      <h1 class="mb-6 text-3xl text-center">{{ formHeading }}</h1>
      <div class="flex flex-col w-full mb-4">
        <label
          class="mb-1 font-semibold text-left text-gray-600 sr-only"
          for="field-title"
          >Title</label
        >
        <input
          class="px-3 py-2 text-3xl font-semibold rounded outline-none ring-1 ring-gray-300 focus:ring-blue-400"
          type="text"
          id="field-title"
          placeholder="Title"
          v-model="fields.title"
        />
      </div>

      <div class="flex-1">
        <editor :init="editorOptions" v-model.lazy="fields.content"></editor>
      </div>

      <p class="mt-2 text-sm text-left text-gray-500 md:text-center">
        <span
          class="px-2 py-1 font-semibold text-gray-500 bg-white rounded shadow"
          >CTRL</span
        >
        +
        <span
          class="px-2 py-1 font-semibold text-gray-500 bg-white rounded shadow"
          >S</span
        >: Save blog
      </p>

      <!-- Form buttons -->
      <div class="absolute bottom-0 right-0 z-30 flex mx-4 mb-6 md:mx-0">
        <button
          @click.prevent="submitted"
          :class="{
            'bg-blue-400 shadow-lg': canSave,
            'bg-gray-300 shadow-none': !canSave,
          }"
          class="flex justify-center w-24 px-6 py-3 mr-4 text-lg font-semibold text-white transition-all duration-300 ease-in-out rounded-md"
        >
          <span v-if="!loading">Save</span>
          <span
            class="block rounded-full w-7 h-7 animate-spin"
            style="border: 4px solid transparent; border-top-color: white"
            v-if="loading"
          ></span>
        </button>

        <button
          @click.prevent="showPostMenu = !showPostMenu"
          class="px-4 py-3 text-xl font-semibold text-gray-100 bg-gray-500 rounded-md shadow-lg md:hidden"
        >
          <svg
            v-if="!showPostMenu"
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
            ></path>
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            ></path>
          </svg>
          <svg
            v-if="showPostMenu"
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
    </div>

    <!-- Blog options -->
    <div
      :class="{ hidden: !showPostMenu }"
      class="absolute top-0 right-0 z-20 h-full overflow-auto bg-white shadow-lg md:relative md:w-96 md:block"
    >
      <h2 class="block p-2 mb-2 text-2xl text-center text-white bg-gray-500">
        Blog Options
      </h2>

      <div class="flex flex-col w-full p-4">
        <label class="mb-1 text-left text-gray-600" for="field-status"
          >Status</label
        >
        <select
          class="px-1 py-1.5 rounded outline-none ring-1 ring-gray-300 focus:ring-blue-400"
          id="field-status"
          v-model.lazy="fields.status"
        >
          <option value="draft">Draft</option>
          <option value="pending">Pending Review</option>
          <option value="future">Scheduled</option>
          <option value="publish">Published</option>
        </select>
      </div>

      <div class="flex flex-col w-full p-4 pt-0">
        <label class="mb-1 text-left text-gray-600" for="field-publish-date"
          >Publish Date</label
        >
        <date-picker
          id="field-publish-date"
          v-model.lazy="fields.date"
          format="YYYY-MM-DD"
          :options="{ firstDay: 0, showWeekNumber: true }"
          class="px-2 py-1 rounded outline-none ring-1 ring-gray-300 focus:ring-blue-400"
        ></date-picker>
      </div>

      <div class="flex flex-col w-full p-4 pt-0">
        <label class="mb-1 text-left text-gray-600" for="field-slug"
          >Slug</label
        >
        <input
          class="px-2 py-1 rounded outline-none ring-1 ring-gray-300 focus:ring-blue-400"
          type="text"
          id="field-slug"
          v-model="fields.slug"
          maxlength="80"
        />
        <p class="mt-1 text-xs text-left text-gray-500">
          The last part of the blog's URL. Use hyphens for spaces. 80 characters
          max.
        </p>
      </div>

      <div class="flex flex-col w-full p-4 pt-0 mb-4">
        <label class="mb-1 text-left text-gray-600" for="field-categories"
          >Categories</label
        >
        <v-select
          id="field-categories"
          multiple
          v-model.lazy="fields.categories"
          :options="availableCategories"
          :reduce="(category) => category.id"
          label="name"
        >
          <template v-slot:no-options> Fetching categories... </template>
        </v-select>
      </div>

      <h2 class="block p-2 mb-2 text-2xl text-center text-white bg-gray-500">
        Featured Image
      </h2>

      <div
        class="flex flex-col items-center justify-center w-full p-4 pt-0 mb-8"
      >
        <wp-file-upload v-model="fields.featured_media" />

        <!-- Update the media item returned by `<wp-file-upload>` with the field `alt_text` on submit -->
        <div class="flex flex-col w-full">
          <label
            class="mb-1 text-left text-gray-600"
            for="field-featured-image-alt"
            >Featured Image Alt Text</label
          >
          <input
            class="px-2 py-1 rounded outline-none ring-1 ring-gray-300 focus:ring-blue-400"
            type="text"
            id="field-featured-image-alt"
            v-model.lazy="mediaAlt"
          />
        </div>
      </div>

      <h2 class="block p-2 mb-2 text-2xl text-center text-white bg-gray-500">
        SEO
      </h2>

      <google-preview
        :favicon="siteFavicon"
        :breadcrumbs="[siteUrl, fields.slug || 'your-slug-here']"
        :title="
          fields.meta._yoast_wpseo_title || fields.title || 'Enter a Title'
        "
        :date="currentNiceDate"
        :description="
          fields.meta._yoast_wpseo_metadesc || 'Add a description below.'
        "
      />

      <div class="flex flex-col w-full p-4">
        <label class="mb-1 text-left text-gray-600" for="field-seo-title"
          >SEO Title</label
        >
        <input
          class="px-2 py-1 rounded outline-none ring-1 ring-gray-300 focus:ring-blue-400"
          type="text"
          id="field-seo-title"
          v-model="fields.meta._yoast_wpseo_title"
        />
      </div>

      <div class="flex flex-col w-full p-4 pt-0">
        <label class="mb-1 text-left text-gray-600" for="field-keyword-phrase"
          >Keyword Phrase</label
        >
        <input
          class="px-2 py-1 rounded outline-none ring-1 ring-gray-300 focus:ring-blue-400"
          type="text"
          id="field-keyword-phrase"
          v-model.lazy="fields.meta._yoast_wpseo_focuskw"
        />
      </div>

      <div class="flex flex-col w-full p-4 pt-0">
        <label class="mb-1 text-left text-gray-600" for="field-excerpt"
          >Excerpt</label
        >
        <textarea
          class="px-2 py-1 rounded outline-none ring-1 ring-gray-300 focus:ring-blue-400"
          type="text"
          id="field-excerpt"
          rows="4"
          v-model.lazy="fields.excerpt"
          maxlength="150"
        >
        </textarea>
        <p class="mt-1 text-xs text-left text-gray-500">
          255 character limit. Use keyword phrase. Write to entice reading.
        </p>
      </div>

      <div class="flex flex-col w-full p-4 pt-0 mb-24">
        <label class="mb-1 text-left text-gray-600" for="field-meta-description"
          >Meta Description</label
        >
        <textarea
          class="px-2 py-1 rounded outline-none ring-1 ring-gray-300 focus:ring-blue-400"
          type="text"
          id="field-meta-description"
          rows="4"
          v-model="fields.meta._yoast_wpseo_metadesc"
        >
        </textarea>
        <p class="mt-1 text-xs text-left text-gray-500">
          150 character limit. Use keywords for Google Search results.
        </p>
      </div>
    </div>
  </form>
</template>

<script>
import http from "../util/http";
import cookies from "../util/cookies";
import moment from "moment";

import WPFileUpload from "./forms/WPFileUpload.vue";
import DatePicker from "./forms/DatePicker.vue";
import GoogleSearchPreview from "./GoogleSearchPreview.vue";
import Editor from "@tinymce/tinymce-vue";

export default {
  data() {
    return {
      loading: false,
      showPostMenu: false,
      mediaAlt: this.featuredImageAlt,
      availableCategories: [],

      siteUrl: cookies.getCookie("blog_url").split("https://")[1],
      siteFavicon: cookies.getCookie("blog_url") + "/favicon.ico",

      fields: {
        title: this.title,
        content: this.content,
        slug: this.slug,
        categories: this.categories,
        excerpt: this.excerpt,
        featured_media: this.featuredImageId,
        date: this.publishDate,
        status: this.status,
        meta: {
          _yoast_wpseo_focuskw: this.keywordPhrase,
          _yoast_wpseo_metadesc: this.metaDescription,
          _yoast_wpseo_title: this.seoTitle,
        },
      },

      editorOptions: {
        contextmenu: false,
        skin_url: "/skins/ui/oxide",
        content_css: "/skins/ui/oxide/content.min.css",
        content_style:
          "body { color: #222; font-size: 18px; font-family: 'Open Sans' }",
        height: "100%",
        resize: false,
        branding: false,
        menubar: false,
        plugins: [
          "lists",
          "advlist",
          "link",
          "image",
          "table",
          "wordcount",
          "code",
          "fullscreen",
        ],
        toolbar:
          "undo redo | formatselect | bold italic bullist numlist | alignleft aligncenter alignright alignjustify | link image table | code | fullscreen",
        init_instance_callback: (editor) => {
          editor.addShortcut("ctrl+s", "Custom Ctrl+S", "custom_ctrl_s");
          editor.addCommand("custom_ctrl_s", () => {
            this.submitted();
          });
        },
        images_upload_handler: this.handleEditorImageUpload,
      },
    };
  },
  computed: {
    canSave() {
      // Perform tests for conditions you can't save under
      if (this.fields.title.length <= 0) return false;

      return true;
    },
    currentNiceDate() {
      let date = moment(this.fields.date).format("MMM D, YYYY");

      if (date === "Invalid date") date = moment().format("MMM D, YYYY");

      return date;
    },
  },
  mounted() {
    http.router = this.$router;

    this.$el.focus();

    http.get("/wp-json/wp/v2/categories", {
      onSuccess: (response) => {
        this.availableCategories = response.data;
      },
    });
  },
  methods: {
    submitted() {
      if (!this.canSave) return;

      this.loading = true;

      // Update uploaded featured image with alt text
      if (this.fields.featured_media && this.mediaAlt) {
        http.post(`/wp-json/wp/v2/media/${this.fields.featured_media}`, {
          data: {
            alt_text: this.mediaAlt,
          },
          onSuccess: () => {
            this.emitEvent();
          },
          // Carry on regardless of whether the alt text is uploaded
          onError: this.onSuccess,
        });
      } else {
        this.emitEvent();
      }
    },
    emitEvent() {
      let allData = {};

      // Only submit data that has been entered
      for (const field in this.fields) {
        if (this.fields[field]) {
          allData[field] = this.fields[field];
        }
      }

      /*
       * Make sure empty array isn't sent for categories. It should at least be placed in
       * Uncategorized automatically by WordPress
       */
      if (allData.categories.length === 0) delete allData.categories;

      // Add time if it doesn't exist
      if (allData.date && !/.+\d\d:\d\d:\d\d/gm.test(allData.date))
        allData.date += " 12:00:00";

      this.$emit("postSubmitted", allData);
    },
    handleEditorImageUpload(blobInfo, success, failure) {
      let form = new FormData();
      form.append("file", blobInfo.blob(), blobInfo.filename());

      http.post("/wp-json/wp/v2/media", {
        data: form,
        onSuccess: (response) => {
          success(
            `${cookies.getCookie("blog_url")}/wp-content/uploads/${
              response.data.media_details.file
            }`
          );
        },
        onError: () => {
          failure("An error has occurred with the image upload.");
        },
      });
    },
  },
  components: {
    Editor,
    DatePicker,
    "wp-file-upload": WPFileUpload,
    "google-preview": GoogleSearchPreview,
  },
  // Pass in props to prepopulate the form, like when editing an existing post.
  props: {
    formHeading: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    slug: {
      type: String,
      default: "",
    },
    excerpt: {
      type: String,
      default: "",
    },
    seoTitle: {
      type: String,
      default: "",
    },
    metaDescription: {
      type: String,
      default: "",
    },
    keywordPhrase: {
      type: String,
      default: "",
    },
    featuredImageId: {
      type: Number,
      default: null,
    },
    featuredImageAlt: {
      type: String,
      default: "",
    },
    publishDate: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      default: "",
    },
    categories: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>