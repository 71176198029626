<template>
  <section>
    <div class="google-preview">
      <div class="google-preview--badge">preview</div>
      <div class="google-preview--top">
        <div class="google-preview--path">
          <img
            class="google-preview--favicon"
            :src="
              computedProps.favicon ? computedProps.favicon : defaultFavicon
            "
            alt=""
          />
          <span class="google-preview--breadcrumbs">{{
            computedProps.breadcrumbs | breadcrumbsFromArray
          }}</span>
        </div>

        <div v-html="computedProps.title" class="google-preview--title"></div>
      </div>

      <div class="google-preview--bottom">
        <div class="google-preview--description">
          <span class="google-preview--date">{{ computedProps.date }} — </span>
          {{ computedProps.description | limitChars(150-3) }}
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&family=Open+Sans&family=Roboto&display=swap");

.google-preview {
  position: relative;
  font-family: "Roboto", sans-serif;
  margin: 16px;
  border-radius: 8px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2), 0 2px 6px rgba(0, 0, 0, 0.1);
}
.google-preview--badge {
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 2px 8px;
  background-color: #43df6d;
  border-radius: 4px;
  font-size: 12px;
  color: #fff;
}
.google-preview--top {
  padding: 12px 14px;
}
.google-preview--path {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  margin-right: 56px;
  overflow: hidden;
  color: #202124;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
}
.google-preview--favicon {
  display: inline;
  height: 16px;
  width: 16px;
  vertical-align: middle;
  margin-right: 12px;
  color: #303124;
  font-size: 16px;
  line-height: 20px;
}
.google-preview--breadcrumbs {
  text-overflow: ellipsis;
  overflow: hidden;
}
.google-preview--title {
  color: #1558d6;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;

  font-weight: 400;
  line-height: 26px;
  text-align: left;
}
.google-preview--bottom {
  padding: 0 16px 12px;
}
.google-preview--description {
  color: #3c4043;
  font-size: 14px;
  text-align: left;
}
.google-preview--date {
  color: #70757a;
}
</style>

<script>
export default {
  data() {
    return {
      defaultFavicon:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABs0lEQVR4AWL4//8/RRjO8Iucx+noO0MWUDo16FYABMGP6ZfUcRnWtm27jVPbtm3bttuH2t3eFPcY9pLz7NxiLjCyVd87pKnHyqXyxtCs8APd0rnyxiu4qSeA3QEDrAwBDrT1s1Rc/OrjLZwqVmOSu6+Lamcpp2KKMA9PH1BYXMe1mUP5qotvXTywsOEEYHXxrY+3cqk6TMkYpNr2FeoY3KIr0RPtn9wQ2unlA+GMkRw6+9TFw4YTwDUzx/JVvARj9KaedXRO8P5B1Du2S32smzqUrcKGEyA+uAgQjKX7zf0boWHGfn71jIKj2689gxp7OAGShNcBUmLMPVjZuiKcA2vuWHHDCQxMCz629kXAIU4ApY15QwggAFbfOP9DhgBJ+nWVJ1AZAfICAj1pAlY6hCADZnveQf7bQIwzVONGJonhLIlS9gr5mFg44Xd+4S3XHoGNPdJl1INIwKyEgHckEhgTe1bGiFY9GSFBYUwLh1IkiJUbY407E7syBSFxKTszEoiE/YdrgCEayDmtaJwCI9uu8TKMuZSVfSa4BpGgzvomBR/INhLGzrqDotp01ZR8pn/1L0JN9d9XNyx0AAAAAElFTkSuQmCC",
    };
  },
  computed: {
    computedProps() {
      return {
        favicon: this.favicon,
        breadcrumbs: this.breadcrumbs,
        title: this.title,
        date: this.date,
        description: this.description,
      };
    },
  },
  props: {
    favicon: {
      type: String,
      required: true,
    },
    // e.g. ['example.com', 'blogs', 'my-new-blog-post'] => example.com > blogs > my-new-blog-post
    breadcrumbs: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  filters: {
    breadcrumbsFromArray(bc) {
      return bc.join(" › ");
    },
    limitChars(str, chars) {
      return str.length > chars ? str.slice(0, chars) + "..." : str;
    },
  },
};
</script>