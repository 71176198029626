<template>
  <div class="flex -mb-2 -mr-2">
    <button
      title="Delete"
      @click="$emit('delete-clicked')"
      class="flex items-center p-2 mr-2 font-semibold text-gray-500 hover:text-red-400 focus:text-red-400"
    >
      <svg
        class="w-6 h-6 md:mr-0.5"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
        ></path>
      </svg>

      <span class="sr-only">Delete</span>
    </button>

    <button
      title="Edit"
      @click="editPost"
      class="flex items-center p-2 mr-2 font-semibold text-gray-500 hover:text-gray-400 focus:text-gray-400"
    >
      <svg
        class="w-6 h-6 md:mr-0.5"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
        ></path>
      </svg>

      <span class="sr-only">Edit</span>
    </button>

    <a
      v-if="status === 'publish'"
      :href="link"
      title="View"
      target="_blank"
      class="flex items-center p-2.5 font-semibold text-gray-500 hover:text-gray-400 focus:text-gray-400"
    >
      <svg
        class="w-6 h-6 md:mr-0.5"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
        ></path>
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
        ></path>
      </svg>

      <span class="sr-only">View</span>
    </a>
  </div>
</template>

<script>
export default {
  methods: {
    editPost() {
      this.$router.push(`/edit/${this.id}`);
    },
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
};
</script>