<template>
  <div class="flex items-center justify-center min-h-screen bg-blue-50">
    <div
      class="w-full px-10 py-8 mx-2 bg-white rounded-md shadow-lg md:w-1/2 xl:w-1/3"
    >
      <h1 class="mb-6 text-3xl text-center">Please Log In:</h1>
      <message-bar
        :message="currentAlertMessage"
        :color="currentAlertColor"
      ></message-bar>
      <form class="flex flex-col items-center" @submit.prevent="onSubmit">
        <div class="flex flex-col w-full mb-4">
          <label
            class="mb-1 font-semibold text-left text-gray-600"
            for="username"
            >Site URL</label
          >
          <input
            class="px-3 py-2 text-lg rounded outline-none ring-1 ring-gray-300 focus:ring-blue-400"
            type="text"
            id="url"
            v-model.lazy="requiredFields.url"
          />
        </div>

        <div class="flex flex-col w-full mb-4">
          <label
            class="mb-1 font-semibold text-left text-gray-600"
            for="username"
            >Username</label
          >
          <input
            class="px-3 py-2 text-lg rounded outline-none ring-1 ring-gray-300 focus:ring-blue-400"
            type="text"
            id="username"
            v-model.lazy="requiredFields.username"
          />
        </div>

        <div class="flex flex-col w-full mb-8">
          <label
            class="mb-1 font-semibold text-left text-gray-600"
            for="password"
            >Password</label
          >
          <input
            class="px-3 py-2 text-lg rounded outline-none ring-1 ring-gray-300 focus:ring-blue-400"
            type="password"
            id="password"
            v-model.lazy="requiredFields.password"
          />
        </div>

        <div class="flex justify-between w-full">
          <div class="flex items-center">
            <input
              class="mr-2"
              type="checkbox"
              id="remember"
              v-model.lazy="remember"
            />
            <label for="remember">Remember Me</label>
          </div>
          <button
            class="flex justify-center w-24 px-6 py-2 font-semibold text-white bg-blue-500 rounded-md shadow-md outline-none hover:bg-blue-400 focus:ring-2 ring-blue-300"
            type="submit"
          >
            <span v-if="!loading">Log in</span>
            <span
              class="block w-6 h-6 rounded-full animate-spin"
              style="border: 4px solid transparent; border-top-color: white"
              v-if="loading"
            ></span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import cookies from "../util/cookies";
import axios from "axios";

import MessageBar from "../components/MessageBar";

export default {
  components: {
    MessageBar,
  },
  data() {
    return {
      requiredFields: {
        url: this.$route.query.connect || cookies.getCookie("blog_url") || "",
        username: "",
        password: "",
      },
      remember: false,
      alertMessage: "",
      alertColor: "",
      loading: false,
    };
  },
  computed: {
    currentAlertMessage() {
      return this.alertMessage;
    },
    currentAlertColor() {
      return this.alertColor;
    },
  },
  methods: {
    onSubmit() {
      for (const field in this.requiredFields) {
        if (this.requiredFields[field].length <= 0) {
          this.alertMessage = "Please fill out all credentials.";
          this.alertColor = "error";
          return;
        }
      }

      if (this.requiredFields.url.startsWith("http://")) {
        this.alertMessage =
          "Unsecured connection not allowed. Please use https.";
        this.alertColor = "error";
        return;
      }

      this.loading = true;

      let connectionUrl = this.requiredFields.url;

      if (connectionUrl.substr(connectionUrl.length - 1) === "/") {
        connectionUrl = connectionUrl.slice(0, -1);
      }

      axios
        .post(`${connectionUrl}/wp-json/jwt-auth/v1/token`, {
          username: this.requiredFields.username,
          password: this.requiredFields.password,
        })
        .then((response) => {
          // console.log(response);

          this.loading = false;

          if (response.data.success) {
            const rememberLoginFor = this.remember ? 7 * 24 : 1;
            cookies.setCookie(
              "login_token",
              response.data.data.token,
              rememberLoginFor
            );

            cookies.setCookie("blog_url", connectionUrl, this.remember ? null : 1); // Don't pass in a value so the function sets it forever

            this.$router.push('/blogs');

            this.alertMessage = "Login successful!";
            this.alertColor = "success";
          } else {
            this.alertMessage =
              response.data.message || "An error has occurred.";
            this.alertColor = "error";
          }
        })
        .catch((error) => {
          this.alertMessage = error.message;
          this.alertColor = "error";
          this.loading = false;
        });
    },
  },
};
</script>