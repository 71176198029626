<template>
  <div
    class="flex flex-col max-w-screen-md p-0 m-auto mb-4 transition-all duration-300 ease-in-out bg-white rounded shadow-md hover:shadow-lg md:mb-8"
  >
    <div
      :class="{
        'bg-green-500': status === 'publish',
        'bg-lightblue-500': status !== 'publish',
      }"
      class="flex items-center justify-between rounded-t"
    >
      <router-link
        :to="`/edit/${id}`"
        title="Edit"
        class="block w-full h-full px-4 py-2 overflow-hidden text-white"
      >
        <h3
          v-html="title"
          class="overflow-hidden text-xl font-semibold text-left whitespace-nowrap"
          style="text-overflow: ellipsis"
        ></h3>
      </router-link>
    </div>

    <div class="flex p-4">
      <a
        v-if="featuredImage"
        :href="link"
        target="_blank"
        class="items-center justify-center hidden w-48 mr-4 sm:flex"
      >
        <img :src="featuredImage" :alt="imageAlt" />
      </a>

      <div class="flex flex-col justify-between flex-1">
        <div class="flex justify-between w-full pb-4 -mt-2">
          <p class="block text-left text-gray-500">by {{ author }}</p>
          <p class="block font-semibold text-left text-gray-600 min-w-max">
            <span v-if="status === 'publish'" class="mr-1 text-gray-400"
              >Published <br class="sm:hidden" /></span
            ><span v-if="status === 'future'" class="mr-1 text-gray-400"
              >Scheduled <br class="sm:hidden" /></span
            >{{ published }}
          </p>
        </div>

        <div class="flex items-end justify-between">
          <div
            v-if="categories.length > 0"
            class="flex flex-wrap -mb-1 md:mb-0"
          >
            <div
              v-for="category in categories"
              :key="category.id"
              :class="{
                'text-green-500 bg-green-100': status === 'publish',
                'text-lightblue-500 bg-lightblue-100': status !== 'publish',
              }"
              class="px-3 py-1 mb-1 mr-1 text-sm rounded md:mr-2 md:mb-0"
            >
              {{ category.name }}
            </div>
          </div>

          <blog-card-actions
            :id="id"
            :status="status"
            :link="link"
            @delete-clicked="$emit('delete-post', id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlogCardActions from "./BlogCardActions.vue";

export default {
  methods: {},
  props: {
    id: {
      type: Number,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    excerpt: {
      type: String,
      default: "",
    },
    categories: {
      type: Array,
      required: true,
    },
    author: {
      type: String,
      required: true,
    },
    published: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    featuredImage: {
      type: String,
      default: "",
    },
    imageAlt: {
      type: String,
      default: "",
    },
  },
  components: {
    BlogCardActions,
  },
};
</script>