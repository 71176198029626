<template>
  <div class="w-full p-4">
    <label
      class="relative block w-full p-8 text-gray-600 border-2 border-gray-400 border-dashed cursor-pointer bg-gray-50"
    >
      <div
        v-if="state === 'default'"
        class="flex flex-col items-center justify-center"
      >
        <svg
          class="w-10 h-10 mb-1"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
          ></path>
        </svg>
        <p class="text-lg">Drop featured image here</p>
      </div>

      <div
        v-if="state === 'uploading'"
        class="flex flex-col items-center justify-center"
      >
        <svg
          class="w-10 h-10 mb-1"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
        <p class="mb-2 text-lg">Uploading...</p>
        <p>
          <img
            :src="file | fileAsUrl"
            alt=""
            class="inline-block w-6 h-6"
          />
          {{ file ? file.name : '' }}
        </p>
      </div>
      <div
        v-if="state === 'success'"
        class="flex flex-col items-center justify-center"
      >
        <svg
          class="w-10 h-10 mb-1 text-green-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
        <p class="mb-2 text-lg">File successfully uploaded!</p>
        <p>
          <img
            :src="file | fileAsUrl"
            alt=""
            class="inline-block w-6 h-6"
          />
          {{ file ? file.name : '' }}
        </p>
      </div>

      <div
        v-if="state === 'failure'"
        class="flex flex-col items-center justify-center"
      >
        <svg
          class="w-10 h-10 mb-1 text-red-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
        <p class="mb-2 text-lg">Problem uploading file.</p>
        <p>
          <img
            :src="file | fileAsUrl"
            alt=""
            class="inline-block w-6 h-6"
          />
          {{ file ? file.name : '' }}
        </p>
      </div>

      <input
        @change="fileChanged($event.target)"
        type="file"
        accept="image/*"
        :disabled="state === 'uploading'"
        class="absolute top-0 bottom-0 left-0 right-0 opacity-0 cursor-pointer"
    /></label>
  </div>
</template>

<script>
import http from "../../util/http";

function fakePost(shouldResolve = true) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      shouldResolve ? resolve() : reject();
    }, 1500);
  });
}

export default {
  data() {
    return {
      // default, uploading, success, failure
      state: "default",
      file: null,
    };
  },
  mounted() {
    http.router = this.$router;
  },
  methods: {
    fileChanged(target) {
      this.state = "uploading";
      this.file = target.files[0];

      let form = new FormData();
      form.append('file', this.file, this.file.name);

      http.post("/wp-json/wp/v2/media?_embed", {
        data: form,
        onSuccess: (response) => {
          this.state = "success";
          this.$emit("input", response.data.id);
        },
        onError: () => {
          this.state = "failure";
        },
      });
    },
  },
  filters: {
    fileAsUrl(file) {
      return file ? URL.createObjectURL(file) : '';
    },
  },
  props: {
    // Allows for <wp-file-upload v-model="foo"></wp-file-upload>
    value: {
      type: Number,
      default: null,
    },
  },
};
</script>