<template>
  <div
    class="px-4 py-2 mb-6 text-center rounded"
    :class="currentColorClasses"
    v-if="currentMessage"
  >
    {{ currentMessage }}
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  computed: {
    currentMessage() {
      return this.message;
    },
    currentColorClasses() {
      const colorLookUp = {
        success: ["text-green-800", "bg-green-200"],
        error: ["text-red-800", "bg-red-200"],
      };

      if (this.color in colorLookUp) {
        return colorLookUp[this.color];
      }

      return "";
    },
  },
};
</script>